import React from 'react'
import { PageProps } from 'gatsby'
import { Layout, MainNav, MainTitle, MetaHeader } from '@components'

const title = '404'
const subtitle = 'Jom Lawati laman-laman kami'

const Error404Page: React.FC<PageProps> = () => (
  <Layout>
    <MetaHeader title={title} description={subtitle} />

    <section className='mx-auto mt-5'>
      <MainTitle title={title} subtitle={subtitle} />
      <MainNav />
    </section>
  </Layout>
)

export default Error404Page
